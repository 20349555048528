import { NewsData } from '#types/api.types';
import { createNews, deleteNews, editNews, getNews } from 'src/utils/api/news';
import { create } from 'zustand';

interface INewsStore {
  news: NewsData[];
  totalCount: number;
  currentPage: number;
  totalPages: number;
  mode: 'user' | 'admin';
  isMobile: boolean;

  actions: {
    firstFetchNews: (mode: 'user' | 'admin', isMobile?: boolean) => void;
    fetchNews: (currentPage: number, mode: 'user' | 'admin', isMobile?: boolean) => void;
    createNews: (data: NewsData) => void;
    deleteNews: (id: number) => void;
    editNews: (data: NewsData) => void;
    hiddenNews: (data: NewsData) => void;
  };
}

const useNewsStore = create<INewsStore>((set, get) => ({
  news: [],
  totalCount: 0,
  currentPage: 0,
  totalPages: 0,
  mode: 'user',
  isMobile: false,

  actions: {
    firstFetchNews: (mode, isMobile) => {
      const { news } = get();
      if (!news.length) {
        getNews(0, mode, isMobile ? 5 : 15).then((res) => {
          const totalCount = res?.data.total_count ?? 0;
          const totalPages = Math.floor(totalCount / 15);
          const news = res?.data.data ?? [];
          set({ news, totalCount, totalPages, isMobile });
        });
      }
    },

    fetchNews: async (currentPage, mode, isMobile) => {
      getNews(currentPage, mode, isMobile ? 5 : 15).then((res) => {
        const totalCount = res?.data.total_count ?? 0;
        const totalPages = Math.floor(totalCount / 15);
        set((state) => {
          const news =
            currentPage !== 0 && isMobile
              ? [...state.news, ...(res?.data.data ?? [])]
              : (res?.data.data ?? []);
          return { news, totalCount, totalPages, isMobile };
        });
      });
    },
    editNews: (data) => {
      editNews(data).then((news) => {
        set((prev) => ({ news: prev.news.map((d) => (d.id === news.id ? news : d)) }));
      });
    },
    hiddenNews: (data) => {
      const { isMobile } = get();
      editNews(data).then((news: NewsData) => {
        getNews(0, 'admin', isMobile ? 5 : 15).then((res) => {
          const totalCount = res?.data.total_count ?? 0;
          const totalPages = Math.floor(totalCount / 15);
          set((state) => {
            const news = isMobile
              ? [...state.news, ...(res?.data.data ?? [])]
              : (res?.data.data ?? []);
            return { news, totalCount, totalPages };
          });
        });
      });
    },
    createNews: (data) => {
      const { totalCount, news, isMobile } = get();
      createNews(data).then((res: NewsData) => {
        getNews(0, 'admin', isMobile ? 5 : 15);
        const total = res.active ? totalCount + 1 : totalCount - 1;
        const totalPages = Math.floor(total / 15);
        news.unshift(res);
        set({ totalCount: total, news, totalPages });
      });
    },

    deleteNews: (id) => {
      const { news, totalCount: totalC } = get();
      deleteNews(id).then(() => {
        const updateNews = news.filter((n) => n.id !== id);
        const totalCount = totalC - 1;
        const totalPages = Math.floor(totalCount / 15);
        set({ news: updateNews, totalCount, totalPages });
      });
    },
  },
}));

export const useNewsActions = () => useNewsStore((state) => state.actions);
export const useNews = () => useNewsStore((state) => state.news);
export const useCount = () => useNewsStore((state) => state.totalCount);
export const useTotalPages = () => useNewsStore((state) => state.totalPages);
