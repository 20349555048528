import { IPropsSVGType } from '#types/svg.types';
import SVGStyle from './SVG.styled';

export default function Error({ color = 'yellow' }: IPropsSVGType) {
  return (
    <SVGStyle width={16} height={16} $color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35707 3.60511C7.08017 2.32841 8.91951 2.32841 9.64261 3.60512L13.9676 11.2413C14.6804 12.4999 13.7712 14.0597 12.3248 14.0597H3.6749C2.22849 14.0597 1.31931 12.4999 2.03213 11.2413L6.35707 3.60511ZM7.3333 11.3333C7.3333 10.9651 7.63177 10.6666 7.99996 10.6666C8.36815 10.6666 8.66663 10.9651 8.66663 11.3333C8.66663 11.7015 8.36815 11.9999 7.99996 11.9999C7.63177 11.9999 7.3333 11.7015 7.3333 11.3333ZM7.99996 5.33327C7.63177 5.33327 7.3333 5.63175 7.3333 5.99994V9.33327C7.3333 9.70146 7.63177 9.99994 7.99996 9.99994C8.36815 9.99994 8.66663 9.70146 8.66663 9.33327V5.99994C8.66663 5.63175 8.36815 5.33327 7.99996 5.33327Z"
      />
    </SVGStyle>
  );
}
