import SVG from './SVG.styled';
import { IPropsSVGType } from '#types/svg.types';

export default function Checked({ width = '72', height = '72' }: IPropsSVGType) {
  return (
    <SVG
      width={width}
      height={height}
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check_circle_outline_20">
        <path
          id="&#226;&#134;&#179; Icon Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.1997 36.5004C61.1997 50.418 49.9172 61.7004 35.9997 61.7004C22.0821 61.7004 10.7997 50.418 10.7997 36.5004C10.7997 22.5828 22.0821 11.3004 35.9997 11.3004C49.9172 11.3004 61.1997 22.5828 61.1997 36.5004ZM66.5997 36.5004C66.5997 53.4003 52.8996 67.1004 35.9997 67.1004C19.0997 67.1004 5.39966 53.4003 5.39966 36.5004C5.39966 19.6005 19.0997 5.90039 35.9997 5.90039C52.8996 5.90039 66.5997 19.6005 66.5997 36.5004ZM47.8088 32.1096C48.8633 31.0552 48.8633 29.3456 47.8088 28.2912C46.7544 27.2368 45.0449 27.2368 43.9905 28.2912L32.3997 39.882L28.0088 35.4912C26.9544 34.4368 25.2449 34.4368 24.1905 35.4912C23.1361 36.5456 23.1361 38.2552 24.1905 39.3096L30.4905 45.6096C31.5449 46.664 33.2544 46.664 34.3088 45.6096L47.8088 32.1096Z"
          fill="#F1B75A"
        />
      </g>
    </SVG>
  );
}
