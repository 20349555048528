import styled from 'styled-components';

export const ButtonHoverStyle = styled.button`
  color: var(--white);
  background-color: var(--main-orange);
  background-image: linear-gradient(to right, var(--white) 0%, var(--white) 100%);
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease;
  border: 2px solid var(--main-orange);
  transition: all 0.25s linear;

  &:is(:hover, :focus) {
    color: var(--main-orange);
    background-size: 100% 100%;
  }
`;
