import SVG from './SVG.styled';

export default function ArrowLeft() {
  return (
    <SVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Used Icon">
        <path
          id="Vector"
          d="M12.2582 6.76619L9.02487 9.99953L12.2582 13.2329C12.5832 13.5579 12.5832 14.0829 12.2582 14.4079C11.9332 14.7329 11.4082 14.7329 11.0832 14.4079L7.2582 10.5829C6.9332 10.2579 6.9332 9.73286 7.2582 9.40786L11.0832 5.58286C11.4082 5.25786 11.9332 5.25786 12.2582 5.58286C12.5749 5.90786 12.5832 6.44119 12.2582 6.76619Z"
          fill="#F1B75A"
        />
      </g>
    </SVG>
  );
}
