import SVG from './SVG.styled';

export default function ArrowRight() {
  return (
    <SVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Used Icon">
        <path
          id="Vector"
          d="M7.7418 13.2338L10.9751 10.0005L7.7418 6.76714C7.4168 6.44214 7.4168 5.91714 7.7418 5.59214C8.0668 5.26714 8.5918 5.26714 8.9168 5.59214L12.7418 9.41714C13.0668 9.74214 13.0668 10.2671 12.7418 10.5921L8.9168 14.4171C8.5918 14.7421 8.0668 14.7421 7.7418 14.4171C7.42513 14.0921 7.4168 13.5588 7.7418 13.2338Z"
          fill="#F1B75A"
        />
      </g>
    </SVG>
  );
}
