import SVG from './SVG.styled';
import { IPropsSVGType } from 'src/types/svg.types';

export default function PhotoDisabled({
  color = 'main-color',
  hover = 'gray-2',
  isHover = true,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10.0553 4.49615C9.84467 4.60201 9.69573 4.72379 9.54101 4.92868C9.25572 5.30644 8.72069 5.33396 8.34293 5.04868C7.96516 4.76339 7.92907 4.23501 8.21436 3.85725C8.4654 3.52481 8.76238 3.22732 9.28555 2.9644C9.80197 2.70489 10.4322 2.57153 11.2235 2.57153H13.621C14.6182 2.57153 15.3623 2.74971 15.9416 3.09316C16.5236 3.4382 16.8464 3.89835 17.0733 4.24499L17.1477 4.35897C17.3434 4.65943 17.4379 4.80451 17.5888 4.92202C17.7119 5.01788 17.9444 5.14296 18.5092 5.14296H18.9286C21.0588 5.14296 22.7857 6.86986 22.7857 9.0001V17.4001C22.7857 17.8735 22.4019 18.3258 21.9286 18.3258C21.4552 18.3258 21.0714 17.8735 21.0714 17.4001V9.0001C21.0714 7.81664 20.112 6.85725 18.9286 6.85725H18.5L18.4938 6.85723C17.6577 6.85485 17.0287 6.65855 16.5356 6.27462C16.1389 5.96573 15.8791 5.55891 15.705 5.2864C15.6815 5.24955 15.6595 5.21516 15.639 5.18379C15.4451 4.88758 15.2986 4.70486 15.0674 4.56776C14.8334 4.42907 14.4204 4.28582 13.621 4.28582H11.2235C10.6228 4.28582 10.2727 4.3869 10.0553 4.49615Z"
          fill="#BDBDBD"/>
        <path d="M12.8704 8.9086C13.0233 8.4606 13.5105 8.2214 13.9585 8.37433C15.276 8.82409 16.3171 9.86569 16.7663 11.1835C16.919 11.6316 16.6796 12.1186 16.2315 12.2713C15.7834 12.4241 15.2964 12.1846 15.1436 11.7365C14.8659 10.9215 14.2195 10.2749 13.4047 9.99669C12.9567 9.84376 12.7175 9.3566 12.8704 8.9086Z"
            fill="#BDBDBD"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.93318 6.84187C3.2364 6.38522 3.63825 5.99869 4.14088 5.71032C4.13855 5.70799 4.13625 5.70566 4.13397 5.70334L3.32255 4.89191C2.98781 4.55718 2.98781 4.01446 3.32255 3.67973C3.65728 3.34499 4.2 3.34499 4.53473 3.67973L21.6776 20.8226C22.0123 21.1573 22.0123 21.7 21.6776 22.0348C21.5102 22.2022 21.2908 22.2858 21.0714 22.2858C21.0286 22.2858 20.9857 22.2826 20.9433 22.2762C20.7684 22.2499 20.6 22.1695 20.4653 22.0348L19.4475 21.0169C18.6637 21.2994 17.772 21.4287 16.7924 21.4287C16.6791 21.4287 16.3175 21.4291 15.8025 21.4298L15.799 21.4298C13.6231 21.4326 8.71959 21.4389 8.21148 21.4287C5.41056 21.4263 3.80826 20.3987 2.9812 18.9778C2.22211 17.6737 2.2227 16.1941 2.22299 15.4695L2.223 15.4287C2.223 15.2923 2.22205 14.9331 2.22077 14.4534C2.2165 12.8452 2.20863 9.88249 2.22103 9.416C2.22287 8.55205 2.41316 7.625 2.93318 6.84187ZM8.5316 10.5128C8.5795 10.4233 8.64098 10.3459 8.71213 10.2816L5.43586 7.0053C4.91639 7.17007 4.58281 7.45657 4.36129 7.79017C4.07702 8.21827 3.9353 8.7911 3.9353 9.42867V9.44197L3.93489 9.45525C3.92296 9.83991 3.93055 12.7209 3.93488 14.3625L3.93489 14.3636C3.93625 14.8808 3.93729 15.2748 3.93729 15.4287C3.93729 16.1773 3.95171 17.2374 4.46278 18.1154C4.91948 18.9 5.88492 19.7144 8.22098 19.7144H8.2312L8.24143 19.7146C8.65894 19.7246 13.502 19.7184 15.7279 19.7156H15.729C16.2786 19.7149 16.6684 19.7144 16.7924 19.7144C17.2561 19.7144 17.673 19.6802 18.0465 19.6159L14.8578 16.4272C14.7931 16.4992 14.7149 16.5613 14.6247 16.6096C13.9916 16.9483 13.2685 17.1399 12.5031 17.1399C10.0161 17.1399 7.99999 15.1238 7.99999 12.6368C7.99999 11.8704 8.19214 11.1464 8.5316 10.5128ZM9.94784 11.5173L13.6226 15.1921C13.2807 15.3421 12.9026 15.4256 12.5031 15.4256C10.9629 15.4256 9.71428 14.177 9.71428 12.6368C9.71428 12.2373 9.79777 11.8592 9.94784 11.5173Z"
            fill="#BDBDBD"/>
    </SVG>
  );
}