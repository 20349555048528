import { sizes } from 'src/utils/consts/sizes';
import styled from 'styled-components';

export const ContainerLoginStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (${sizes.min_tablet}) {
    flex-direction: row;
  }

  @media screen and (min-width: 1920px) {
    width: 1920px;
  }
`;

export const BackgroundImageStyle = styled.img<{ src: string }>`
  height: 100%;
  width: 100%;

  background-image: url(${(props) => props.src});
  object-fit: cover;
  object-position: 0 20%;
  @media screen and (${sizes.min_tablet}) {
    height: 100vh;
    object-position: left top;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 196px;
  width: 100%;

  @media screen and (${sizes.min_tablet}) {
    width: calc(100% - 520px);
  }
  @media screen and (${sizes.desktop}) {
    width: calc(100% - 620px);
  }
  @media screen and (${sizes.large_desktop}) {
    width: calc(100% - 720px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    @media screen and (${sizes.min_tablet}) {
      display: none;
    }
  }
`;

export const SectionLoginStyle = styled.div`
  z-index: 3;
  margin-top: -16px;
  background-color: #fff;
  padding: 24px 10px 30px 10px;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 100%;
  @media screen and (${sizes.min_tablet}) {
    box-shadow: -4px 0px 16px -5px rgba(0, 0, 0, 0.6);
    margin-top: initial;
    margin-left: -16px;
    padding: 48px 60px 28px 60px;
    border-radius: 16px 0 0 16px;
    width: 536px;
  }
  @media screen and (${sizes.desktop}) {
    width: 636px;
  }
  @media screen and (${sizes.large_desktop}) {
    width: 736px;
  }
`;

export const WrapperFormStyle = styled.div`
  max-width: 380px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (${sizes.min_tablet}) {
    max-width: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
