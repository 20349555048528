import { IPropsSVGType } from '#types/svg.types';
import {
  User,
  Users,
  Settings,
  Car,
  Book,
  Add,
  Info,
  Books,
  Price,
} from '../../components/icons';

export const permissionsPages = {
  default: [
    {
      Icon: Settings,
      required: true,
      title: 'Головна',
      page: '/',
      views: ['view_news'],
      edits: ['writing_news'],
      panel: true,
    },
    {
      Icon: Book,
      required: true,
      title: 'Прайс лист',
      page: '/pricelist',
      views: ['view_eva', 'view_textiles'],
      edits: ['edit_eva', 'edit_textiles'],
      panel: false,
    },
    {
      Icon: Car,
      required: true,
      title: 'Каталог автомобілів',
      page: '/catalog',
      views: ['view_car_catalog'],
      edits: [
        'edit_car_model_number',
        'edit_car_workshop_model_number',
        'edit_car_photo',
        'edit_car_group',
        'edit_car_prefix',
        'edit_car_model',
        'edit_car_years',
        'edit_car_body_type',
        'edit_car_grade_model',
        'edit_car_type_clips',
        'edit_car_count_clips',
        'edit_car_set_mats',
        'edit_car_specification',
        'edit_car_is_leg',
        'edit_car_is_3d_leg',
        'edit_car_is_3d_shape',
        'edit_car_position_border',
        'edit_car_drop_price',
        'edit_car_price',
        'edit_car_comenth',
        'edit_car_status',
      ],
      panel: true,
    },
    {
      Icon: Books,
      required: true,
      title: 'Генератор артикулів',
      page: '/article',
      views: ['view_articles_generation'],
      edits: ['view_articles_generation'],
      panel: false,
    },
    {
      Icon: Info,
      required: false,
      title: 'Відео інструкції',
      page: '/education',
      views: [],
      edits: [],
      panel: false,
    },
    {
      Icon: Add,
      required: true,
      title: 'Додати автомобіль',
      page: '/add-car',
      views: ['view_add_car_page'],
      edits: ['edit_add_car_page'],
      panel: false,
    },
    // {
    //   Icon: Shipment,
    //   required: true,
    //   title: 'Збірка та відправка',
    //   page: '/shipment-ttn',
    //   views: ['view_logistics'],
    //   edits: ['editing_logistics'],
    //   panel: false,
    // },
    {
      Icon: Price,
      required: false,
      title: 'Генератор цін',
      page: '/price-goods',
      views: ['view_price_calculator'],
      edits: ['edit_price_calculator'],
      panel: false,
    },
  ],
  admin: [
    {
      Icon: Users,
      required: true,
      title: 'Користувачі',
      page: '/users',
      views: ['view_clients', 'view_employees'],
      edits: ['edit_clients', 'edit_employees'],
      panel: false,
    },
    {
      Icon: User,
      required: true,
      title: 'Ролі',
      page: '/roles',
      views: ['view_roles'],
      edits: ['edit_roles'],
      panel: false,
    },
  ],
};

type Pages = {
  title: string;
  required: boolean;
  views: string[];
  Icon: (props: IPropsSVGType) => JSX.Element;
  page: string;
  panel: boolean;
}[];

export const findPages = (p: Pages, perms: string[]) => {
  const pages = p.filter(({ required, views }) =>
    required ? perms.some((perm) => views.includes(perm)) : true
  );
  return pages;
};

export const adminPanel = (path: string, permissions: string[]) => {
  const panel = permissionsPages.default.some(
    (p) => p.page === path && p.panel && p.edits.some((e) => permissions.includes(e))
  );
  return panel;
};
