import { usePermissions } from 'src/store/authStore';
import Messengers from '../links/Messengers';
import {
  AsideBox,
  ButtonChangeAside,
  ButtonExitStyle,
  ButtonPanelStyle,
  ListLiStyle,
  ListPagesStyle,
  ListStyle,
  LinkStyle,
  ChatBoxStyle,
  LogoBoxStyle,
} from './Aside.styled';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo, ArrowLeft, ArrowRight, Admin, Exit } from '../icons';
import { permissionsPages, findPages, adminPanel } from '../../utils/consts/permissions';
import { useAuth } from 'src/providers/AuthProvider';
import { useAdminActions, useIsAdminPanel } from 'src/store/adminStore';
import { useNewsActions } from 'src/store/newsStore';

export default function AsideModal() {
  const permissions = usePermissions();
  const [isActiveAside, setIsActiveAside] = useState<boolean>(false);
  const location = useLocation();
  const { handleLogout } = useAuth();
  const { changePanel } = useAdminActions();
  const isAdminPanel = useIsAdminPanel();
  const { fetchNews } = useNewsActions();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return (location.pathname.includes('catalog') && path.includes('catalog')) ||
      (location.pathname.includes('pricelist') && path.includes('pricelist'))
      ? true
      : location.pathname === path;
  };

  const changeAside = () => {
    setIsActiveAside((prev) => !prev);
  };

  const clickPanel = () => {
    isActiveAside && changeAside();
    updateNews();
    changePanel();
  };

  const updateNews = () => {
    if (location.pathname !== '/') return;
    fetchNews(0, !isAdminPanel ? 'admin' : 'user', false);
  };

  const defPages = findPages(permissionsPages.default, permissions);
  const admPages = findPages(permissionsPages.admin, permissions);

  return (
    <AsideBox $isActive={isActiveAside}>
      <LogoBoxStyle $isActive={isActiveAside}>
        <Logo l={isActiveAside} />
      </LogoBoxStyle>
      <ButtonChangeAside $isActive={isActiveAside} onClick={() => changeAside()}>
        {isActiveAside ? <ArrowLeft /> : <ArrowRight />}
      </ButtonChangeAside>
      <ListPagesStyle $isActive={isActiveAside}>
        {defPages.length && (
          <ListStyle $isActive={isActiveAside}>
            {defPages.map(({ title, page, Icon }, i) => {
              const isActiveHover = isActive(page);
              return (
                <ListLiStyle key={i} $isActive={isActiveHover}>
                  <LinkStyle
                    to={page}
                    $isActive={isActiveHover}
                    $isAside={isActiveAside}
                    onClick={() => isActiveAside && changeAside()}
                  >
                    {<Icon hover="main-orange" isHover={true} isActive={isActiveHover} />}
                    {isActiveAside && title}
                  </LinkStyle>
                </ListLiStyle>
              );
            })}
          </ListStyle>
        )}
        {admPages.length ? (
          <ListStyle $isActive={isActiveAside}>
            {admPages.map(({ title, page, Icon }, i) => {
              const isActiveHover = isActive(page);
              return (
                <ListLiStyle key={i} $isActive={isActiveHover}>
                  <LinkStyle
                    to={page}
                    $isActive={isActiveHover}
                    $isAside={isActiveAside}
                    onClick={() => isActiveAside && changeAside()}
                  >
                    {<Icon hover="main-orange" isHover={true} isActive={isActiveHover} />}
                    {isActiveAside && title}
                  </LinkStyle>
                </ListLiStyle>
              );
            })}
            {adminPanel(location.pathname, permissions) && (
              <ListLiStyle>
                <ButtonPanelStyle
                  type="button"
                  $isActive={isActiveAside}
                  onClick={() => clickPanel()}
                >
                  <Admin color="main-orange" />
                  {isActiveAside && (isAdminPanel ? 'До юзер-панелі' : 'До Адмін-панелі')}
                </ButtonPanelStyle>
              </ListLiStyle>
            )}
          </ListStyle>
        ) : (
          <ListStyle $isActive={isActiveAside}>
            {adminPanel(location.pathname, permissions) && (
              <ListLiStyle>
                <ButtonPanelStyle
                  type="button"
                  $isActive={isActiveAside}
                  onClick={() => clickPanel()}
                >
                  <Admin color="main-orange" />
                  {isActiveAside && (isAdminPanel ? 'До юзер-панелі' : 'До Адмін-панелі')}
                </ButtonPanelStyle>
              </ListLiStyle>
            )}
          </ListStyle>
        )}
      </ListPagesStyle>
      <ChatBoxStyle>
        {isActiveAside && 'ЧатБот:'}
        <Messengers chatbots={true} isActive={!isActiveAside} />
      </ChatBoxStyle>
      <ButtonExitStyle
        type="button"
        $isActive={isActiveAside}
        onClick={() => {
          handleLogout();
          navigate('/login');
        }}
      >
        {isActiveAside ? 'Вийти' : <Exit hover="main-orange" isHover={true} />}
      </ButtonExitStyle>
    </AsideBox>
  );
}
