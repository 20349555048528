import Routes from './routes/Routes';
import AuthProvider from './providers/AuthProvider';
import GlobalStyle from './global';

export default function App() {
  return (
    <AuthProvider>
      <GlobalStyle />
      <Routes />
    </AuthProvider>
  );
}
