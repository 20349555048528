import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { sizes } from 'src/utils/consts/sizes';
import styled from 'styled-components';
import { Burger, Logo } from '../icons';
import MobilePopup from '../modals/MobilePopup';
import AsideModal from '../aside/Aside';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  @media screen and (${sizes.min_tablet}) {
    flex-direction: row;
    width: 100%;
  }
`;
const ButtonBurger = styled.button`
  display: flex;
  align-items: center;
  background-color: initial;
  border: none;
  background-color: var(--orange-3);
  border-radius: 50px;
  padding: 10px;
`;

const HeaderBurgerStyle = styled.div<{ $isMobile: boolean }>`
  @media screen and (${sizes.max_mobile}) {
    position: fixed;
    top: 0;
    left: 0;
    width: ${window.innerWidth}px;
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    z-index: 100;
  }
  @media screen and (${sizes.min_tablet}) {
    display: none;
  }
`;

const SectionContainerStyle = styled.section<{ $location?: boolean }>`
  margin-top: 80px;
  display: flex;
  flex-position: column;
  align-items: center;
  justify-content: ${(props) => (props.$location ? 'initial' : 'center')};
  position: relative;
  
  @media screen and (${sizes.min_tablet}) {
    position: absolute;
    top: 0;
    left: 72px;
    margin-top: 42px;
    margin-left: 48px;
  }
`;

export default function HomeLayout() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const location = useLocation();
  const changeModal = () => {
    setIsOpenModal((prev) => !prev);
  };
  const checkLocation = (path: string) => {
    return (location.pathname.includes('catalog') && path.includes('catalog')) ||
      // (location.pathname.includes('pricelist') && path.includes('pricelist')) ||
      (location.pathname.includes('roles') && path.includes('roles')) ||
      (location.pathname.includes('users') && path.includes('users'))
      ? true
      : location.pathname === path;
  };

  useEffect(() => {
    const changeW = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', changeW);
    return () => {
      return window.removeEventListener('resize', changeW);
    };
  }, []);

  useEffect(() => {
    if (isMobile && isOpenModal) document.body.style.overflow = 'hidden';
    if (isMobile && !isOpenModal) document.body.style.overflow = '';
  }, [isMobile, isOpenModal]);

  return (
    <ContainerStyle>
      {isMobile && (
        <>
          <HeaderBurgerStyle $isMobile={isMobile}>
            <Logo l={true} />
            <ButtonBurger type="button" onClick={() => changeModal()}>
              <Burger />
            </ButtonBurger>
          </HeaderBurgerStyle>
          {isOpenModal && <MobilePopup changeModal={changeModal} />}
        </>
      )}
      {!isMobile && <AsideModal />}
      <SectionContainerStyle $location={checkLocation(location.pathname)}>
        <Suspense>
          <Outlet />
        </Suspense>
      </SectionContainerStyle>
    </ContainerStyle>
  );
}
