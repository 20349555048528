import SVG from './SVG.styled';

export default function Delete() {
  return (
    <SVG
      $hover="main-orange"
      $isHover={true}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 1C14.1927 1 15.6085 2.09609 15.9851 4.13669C15.989 4.15797 15.9966 4.17922 15.9995 4.2004L21.1 4.2C21.5971 4.2 22 4.60294 22 5.1C22 5.55882 21.6567 5.93745 21.2129 5.99299L21.1 6H19.999L18.6192 18.4216C18.4619 19.8309 17.3118 20.9106 15.9131 20.9947L15.7371 21H8.26294C6.78576 21 5.54463 19.8897 5.38082 18.4216L3.995 6H2.9C2.40294 6 2 5.59706 2 5.1C2 4.64118 2.34334 4.26255 2.78711 4.20701L2.9 4.2L8.01227 4.20104C8.00978 4.20069 8.00727 4.20034 8.00476 4.2C8.00722 4.1766 8.0106 4.16021 8.01494 4.13669C8.39148 2.09609 9.80726 1 12 1ZM18.193 6H5.804L7.16972 18.222C7.22298 18.6993 7.57646 19.0769 8.02922 19.175L8.14436 19.1937L8.26294 19.2H15.7371C16.2974 19.2 16.7681 18.7788 16.8303 18.222L18.193 6ZM12 7.5C12.4588 7.5 12.8375 7.84334 12.893 8.28711L12.9 8.4V16.6C12.9 17.0971 12.4971 17.5 12 17.5C11.5412 17.5 11.1625 17.1567 11.107 16.7129L11.1 16.6V8.4C11.1 7.90294 11.5029 7.5 12 7.5ZM15.4548 7.50167C15.9127 7.52959 16.2698 7.89534 16.2982 8.34166L16.2983 8.45478L15.7983 16.6548C15.7681 17.1509 15.3414 17.5286 14.8452 17.4983C14.3873 17.4704 14.0302 17.1047 14.0018 16.6583L14.0017 16.5452L14.5017 8.34522C14.5319 7.84909 14.9586 7.47142 15.4548 7.50167ZM9.48446 8.23297L9.49833 8.34522L9.99833 16.5452C10.0286 17.0414 9.65091 17.4681 9.15478 17.4983C8.69681 17.5263 8.29798 17.2066 8.21554 16.767L8.20167 16.6548L7.70167 8.45478C7.67142 7.95864 8.04909 7.53192 8.54522 7.50167C9.00319 7.47374 9.40202 7.7934 9.48446 8.23297ZM12 2.8C10.7578 2.8 10.1075 3.22659 9.84486 4.19782L9.834 4.2L14.1548 4.20087L14.1554 4.19877C13.8929 3.22686 13.2426 2.8 12 2.8Z" />
    </SVG>
  );
}
