import styled, { css } from 'styled-components';
import { sizes } from 'src/utils/consts/sizes';
import { Link } from 'react-router-dom';
import { ButtonHoverStyle } from '../buttons/Button.styled';

export const BackdropStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  @media screen and (${sizes.min_tablet}) {
    display: none;
  }
`;

export const ModalStyle = styled.div`
  background-color: #fff;
  position: fixed;

  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    width: 4px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--main-orange);
  }

  animation: move-left 200ms linear 1;

  @keyframes move-left {
    from {
      left: 100%;
    }
    to {
      left: 20%;
    }
  }

  @media screen and (${sizes.max_mobile}) {
    width: 80%;
    right: 0;
  }
  height: 100%;
`;

export const HeaderBoxStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-left: 22px;
  padding-right: 14px;
  margin-bottom: 88px;
`;

export const ButtonCloseStyle = styled.button`
  border: initial;
  background-color: transparent;
  &:is(:hover, :focus) {
    background-color: transparent;
  }
`;

export const ListStyle = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  margin-left: 22px;
  &::after {
    content: '';
    display: block;
    width: 200px;
    heigth: 1px;
    margin-bottom: 32px;
    border: 1px solid var(--orange-3);
  }
`;

export const LinkStyle = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  ${(props) =>
    props.$isActive &&
    css`
      font-size: 16px;
      color: var(--main-orange);
    `}
`;

export const ListLiStyle = styled.li`
  transition: all var(--animate);
  &:is(:hover, :focus) {
    font-size: 16px;
    color: var(--main-orange);
    svg {
      fill: var(--main-orange);
      stroke: var(--main-orange);
    }
  }
`;

export const ChatBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  margin-left: 22px;
  span {
    font-size: 14px;
    font-weigth: 600;
    line-height: 1.5;
  }
`;

export const ButtonLogoutStyle = styled(ButtonHoverStyle)`
  width: 175px;
  padding: 12px 24px;
  margin-top: 20px;
  margin-bottom: 34px;
  margin-left: 22px;
`;
export const ButtonPanelStyle = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: initial;
  padding: 12px 24px;
  color: var(--main-orange);
`;
